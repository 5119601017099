<template>
  <div id="app">
    <header>
      <img src="./static/logo.png" class="logo" /><span
        >Fkmalls - 网站导航</span
      >
    </header>
    <!-- 轮播图区域  具体内容在data中 swiperList 内配置 -->
    <div class="swiper">
      <el-carousel
        :interval="2000"
        type="card"
        :height="curHeight"
        trigger="click"
      >
        <el-carousel-item
          v-for="item in swiperList"
          :key="item.url + item.title"
        >
          <div class="swiperBox">
            <h3 class="medium">
              <a :href="item.href" target="_blank">{{ item.title }}</a>
            </h3>
            <img :src="item.url" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 规范列表区域  具体内容在data中 mainList 内配置 -->
    <main>
      <div v-for="(item, index) in mainList" :key="item + index">
        <h4 class="text-gray">
          <i :class="item.icon"></i><span>{{ item.title }}</span>
        </h4>
        <div class="row">
          <a
            :class="{ rowItem: true, phone: isPhone }"
            :href="v.href"
            v-for="(v, i) in item.itemList"
            :key="v + i"
            target="_blank"
          >
            <img :src="v.src" />
            <div class="textMain">
              <p class="mainTitie">{{ v.mainTitie }}</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="v.mainContent"
                placement="top-start"
              >
                <p class="mainContent">{{ v.mainContent }}</p>
              </el-tooltip>
            </div>
          </a>
        </div>
      </div>
    </main>
    <footer>
      <span
        >© 2021-2022 <strong>网站导航</strong> design by
        <strong>FKMALLS</strong></span
      >
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperList: [
        // {
        //   title: "C2M-SP选品",
        //   url: require("./static/banner/c2m-dsp2.jpeg"),
        //   href: "https://dsp.fkmalls.com/",
        // },
        // {
        //   title: "C2M-运营管理",
        //   url: require("./static/banner/c2m-admin.jpeg"),
        //   href: "https://c2m.fkmalls.com/",
        // },
        {
          title: "福猫官网",
          url: require("./static/banner/商城.jpeg"),
          href: "https://www.fkmalls.com/",
        },
        {
          title: "开发平台",
          url: require("./static/banner/banner-open.jpeg"),
          href: "https://open.fkmalls.com/",
        },
        {
          title: "CBI-进口运营管理",
          url: require("./static/banner/跨境.jpeg"),
          href: "https://scm.fkmalls.com/",
        },
      ],
      mainList: [
        {
          title: "CBI-跨境进口",
          icon: "el-icon-s-home",
          itemList: [
            {
              src: require("./static/logo.png"),
              href: "http://test.fkmalls.com/index",
              mainTitie: "CBI-跨境进口-测试环境",
              mainContent: "CBI-跨境进口-测试环境 后台管理系统",
            },
            {
              src: require("./static/logo.png"),
              href: "https://scm.fkmalls.com/",
              mainTitie: "CBI-跨境进口-生产环境",
              mainContent: "CBI-跨境进口-生产环境 后台管理系统",
            },
            // {
            //   src: require("./static/logo.png"),
            //   href: "",
            //   mainTitie: "CBI-跨境进口-开发环境",
            //   mainContent: "CBI-跨境进口-开发环境 后台管理系统",
            // },
            {
              src: require("./static/icons/api.jpeg"),
              href: "http://testapi.fkmalls.com",
              mainTitie: "CBI-API-非生产环境",
              mainContent: "CBI-API-非生产环境 三方系统对接",
            },
            {
              src: require("./static/icons/api.jpeg"),
              href: "http://scmapi.fkmalls.com",
              mainTitie: "CBI-API-生产环境",
              mainContent: "CBI-API-生产环境 三方系统对接",
            },
          ],
        },
        {
          title: "FPA-金融供应链",
          icon: "el-icon-s-home",
          itemList: [
            {
              src: require("./static/logo.png"),
              href: "http://fpa-t.fkmalls.com/index",
              mainTitie: "FPA-金融供应链-测试环境",
              mainContent: "FPA-金融供应链-测试环境 后台管理系统",
            },
            {
              src: require("./static/logo.png"),
              href: "https://fpa.fkmalls.com/",
              mainTitie: "FPA-金融供应链-生产环境",
              mainContent: "FPA-金融供应链-生产环境 后台管理系统",
            },
          ],
        },
        // {
        //   title: "C2M-跨境出口",
        //   icon: "el-icon-s-platform",
        //   itemList: [
        //     {
        //       src: require("./static/icons/c2m.jpeg"),
        //       href: "http://c2m-t.fkmalls.com/",
        //       mainTitie: "C2M-运营管理系统-测试环境",
        //       mainContent:
        //         "C2M-运营管理系统-测试环境； 商品库，订单履约，物流承运等功能",
        //     },
        //     {
        //       src: require("./static/icons/c2m.jpeg"),
        //       href: "https://c2m.fkmalls.com/",
        //       mainTitie: "C2M-运营管理系统-生产环境",
        //       mainContent:
        //         "C2M-运营管理系统-生产环境； 商品库，订单履约，物流承运等功能",
        //     },
        //     {
        //       src: require("./static/icons/c2m.jpeg"),
        //       href: "",
        //       mainTitie: "C2M-运营管理系统-开发环境",
        //       mainContent:
        //         "C2M-运营管理系统-开发环境； 商品库，订单履约，物流承运等功能",
        //     },
        //     {
        //       src: require("./static/icons/api.jpeg"),
        //       href: "http://api-t.fkmalls.com",
        //       mainTitie: "C2M-API-开发环境（大陆）",
        //       mainContent: "C2M-API-开发环境； 三方接口对接及接口回调",
        //     },
        //     {
        //       src: require("./static/icons/c2m-sp.jpeg"),
        //       href: "http://dsp-t.fkmalls.com/",
        //       mainTitie: "C2M-SP选品-测试环境",
        //       mainContent:
        //         "C2M-SP选品-测试环境； 渠道商后台选品，订单管理，资产管理",
        //     },
        //     {
        //       src: require("./static/icons/c2m-sp.jpeg"),
        //       href: "https://dsp.fkmalls.com/",
        //       mainTitie: "C2M-SP选品-生产环境",
        //       mainContent:
        //         "C2M-SP选品-生产环境 渠道商后台选品，订单管理，资产管理",
        //     },
        //     {
        //       src: require("./static/icons/c2m-sp.jpeg"),
        //       href: "",
        //       mainTitie: "C2M-SP选品-开发环境1111111111111111",
        //       mainContent:
        //         "C2M-SP选品-开发环境； 渠道商后台选品，订单管理，资产管理",
        //     },
        //     {
        //       src: require("./static/icons/api.jpeg"),
        //       href: "http://api.fkmalls.com",
        //       mainTitie: "C2M-API-开发环境（香港）",
        //       mainContent: "C2M-API-开发环境； 三方接口对接及接口回调",
        //     },
        //   ],
        // },
        {
          title: "CBM-跨境医药",
          icon: "el-icon-s-platform",
          itemList: [
            {
              src: require("./static/logo.png"),
              href: "http://cbm-t.fkmalls.com/",
              mainTitie: "CBM-福猫医药-测试环境",
              mainContent: "CBM-福猫医药-测试环境",
            },
            {
              src: require("./static/logo.png"),
              href: "http://cbm-admin-t.fkmalls.com/",
              mainTitie: "CBM-福猫医药管理系统-测试环境",
              mainContent: "CBM-福猫医药管理系统-测试环境",
            },
          ],
        },
        {
          title: "内部支持系统",
          icon: "el-icon-s-help",
          itemList: [
          {
              src: require("./static/icons/tapd-logo.png"),
              href: "www.tapd.cn",
              mainTitie: "TAPD",
              mainContent: "腾讯敏捷产品研发平台",
            },
            {
              src: require("./static/icons/confluence.jpeg"),
              href: "http://doc.fkmalls.com/",
              mainTitie: "Confluence WIKI文档",
              mainContent: "技术文档，开发规范，产品PRD,项目管理等",
            },
            {
              src: require("./static/icons/zentao.jpeg"),
              href: "http://zentao.fkmalls.com/zentao/my/",
              mainTitie: "Zentao 禅道",
              mainContent:
                "产品需求与计划；项目迭代，任务拆解，测试用例，Bug管理",
            },
            {
              src: require("./static/icons/gitlab.jpeg"),
              href: "http://gitlab.fkmalls.com/",
              mainTitie: "Gitlab 代码私有仓库",
              mainContent:
                "自建Git代码私有中央仓库，注意不允许自建创建个人groups",
            },
            {
              src: require("./static/icons/nexus.png"),
              href: "http://mvn.fkmalls.com/",
              mainTitie: "Maven 私有仓库",
              mainContent: "Sonatype Nexus Maven私服务",
            },
            {
              src: require("./static/icons/aliyun_mail.png"),
              href: "https://qiye.aliyun.com/",
              mainTitie: "阿里云 企业邮箱",
              mainContent: "xxx@fkmalls.com企业邮箱WEB入口",
            },
            {
              src: require("./static/icons/lanhu.jpeg"),
              href: "https://lanhuapp.com/",
              mainTitie: "蓝湖-高效的产品设计协作平台",
              mainContent: "产品PRD原型设计；蓝湖-高效的产品设计协作平台",
            },
            {
              src: require("./static/icons/grafana_icon.svg"),
              href: "http://grafana.fkmalls.com/",
              mainTitie: "Grafana 监控可视化中心",
              mainContent: "Grafana是一个跨平台、开源的数据可视化网络应用程序平台。",
            },
            {
              src: require("./static/icons/prometheus_icon.png"),
              href: "http://prometheus.fkmalls.com/",
              mainTitie: "Prometheus 新一代监控框架",
              mainContent: "Prometheus是一个开源的系统监控和报警系统，现在已经加入到CNCF基金会，成为继k8s之后第二个在CNCF托管的项目，在kubernetes容器管理系统中，通常会搭配prometheus进行监控，同时也支持多种exporter采集数据，还支持pushgateway进行数据上报，Prometheus性能足够支撑上万台规模的集群。",
            },
            {
              src: require("./static/icons/portainer_icon.jpeg"),
              href: "http://portainer.fkmalls.com/",
              mainTitie: "Portainer 一款轻量的docker可视化管理工具",
              mainContent: "Portainer是Docker的图形化管理工具，提供状态显示面板、应用模板快速部署、容器镜像网络数据卷的基本操作（包括上传下载镜像，创建容器等操作）、事件日志显示、容器控制台操作、Swarm集群和服务等集中管理和操作、登录用户管理和控制等功能。",
            },
          ],
        },
        {
          title: "公共能力",
          icon: "el-icon-view",
          itemList: [
            {
              src: require("./static/icons/cdn.jpeg"),
              href: "http://cdn.fkmalls.com/",
              mainTitie: "静态资源服务-大陆地区",
              mainContent: "静态资源服务-大陆地区",
            },
            // {
            //   src: require("./static/icons/cdn.jpeg"),
            //   href: "http://cdn-hk.fkmalls.com/",
            //   mainTitie: "静态资源服务-香港地区",
            //   mainContent: "静态资源服务-香港地区",
            // },
            /*
            {
              src: require("./static/icons/res-proxy.jpeg"),
              href: "http://res-t.fkmalls.com",
              mainTitie: "三方资源代理-大陆地区",
              mainContent:
                "三方资源代理-非生产环境；使用福猫资源地址代理访问必要供应商图库资源",
            },*/
            // {
            //   src: require("./static/icons/res-proxy.jpeg"),
            //   href: "http://res.fkmalls.com",
            //   mainTitie: "三方资源代理-香港地区",
            //   mainContent:
            //     "三方资源代理-生产环境；使用福猫资源地址代理访问必要供应商图库资源",
            // },
            // {
            //   src: require("./static/icons/open.jpeg"),
            //   href: "http://open-t.fkmalls.com",
            //   mainTitie: "开放平台-测试环境",
            //   mainContent: "开放平台-测试环境",
            // },
            {
              src: require("./static/icons/open.jpeg"),
              href: "http://open.fkmalls.com",
              mainTitie: "开放平台-生产环境-大陆+香港地区",
              mainContent: "开放平台-生产环境-大陆+香港地区",
            },
          ],
        },
        {
          title: "微服务管理",
          icon: "el-icon-s-management",
          itemList: [
            {
              src: require("./static/icons/nacos.png"),
              href: "http://39.101.139.254:8848/nacos/#/login",
              mainTitie: "Nacos-本地|开发|测试环境",
              mainContent:
                "面向进口，出口等业务；动态服务发现、配置和服务管理平台，提供注册中心、配置中心和动态DNS服务三大功能",
            },
            {
              src: require("./static/icons/nacos.png"),
              href: "http://120.55.102.72:8848/nacos/index.html",
              mainTitie: "Nacos-进口生产环境",
              mainContent:
                "面向进口业务；动态服务发现、配置和服务管理平台，提供注册中心、配置中心和动态DNS服务三大功能",
            },
            // {
            //   src: require("./static/icons/nacos.png"),
            //   href: "http://47.243.80.80:8848/nacos/index.html",
            //   mainTitie: "Nacos-出口生产环境",
            //   mainContent:
            //     "面向出口业务；动态服务发现、配置和服务管理平台，提供注册中心、配置中心和动态DNS服务三大功能",
            // },
          ],
        },
        {
          title: "消息中间件",
          icon: "el-icon-s-comment",
          itemList: [
            {
              src: require("./static/icons/rabbitmq.jpeg"),
              href: "http://39.101.139.254:15672/",
              mainTitie: "RabbitMQ-本地|开发|测试环境",
              mainContent:
                "面向进口，出口等业务；全托管的消息队列产品，支持事务消息、顺序消息、优先级队列、死信队列、延时队列等高级消息服务特性，完全兼容AMQP协议",
            },
            {
              src: require("./static/icons/rabbitmq.jpeg"),
              href: "http://120.55.102.72:15672/",
              mainTitie: "RabbitMQ-进口生产环境",
              mainContent:
                "面向进口业务；全托管的消息队列产品，支持事务消息、顺序消息、优先级队列、死信队列、延时队列等高级消息服务特性，完全兼容AMQP协议",
            },
            // {
            //   src: require("./static/icons/rabbitmq.jpeg"),
            //   href: "http://47.243.80.80:15672/",
            //   mainTitie: "RabbitMQ-出口生产环境",
            //   mainContent:
            //     "面向出口业务；全托管的消息队列产品，支持事务消息、顺序消息、优先级队列、死信队列、延时队列等高级消息服务特性，完全兼容AMQP协议",
            // },
          ],
        },
        {
          title: "快捷链接",
          icon: "el-icon-link",
          itemList: [
            {
              src: require("./static/icons/sop.jpeg"),
              href: "http://doc.fkmalls.com/display/SOP",
              mainTitie: "系统SOP操作手册",
              mainContent: "系统SOP操作手册",
            },
          ],
        },
      ],
      isPhone: false,
      curHeight: 0,
    };
  },
  beforeMount() {
    var h = document.documentElement.clientHeight || document.body.clientHeight;
    var w = document.documentElement.clientWidth || document.body.clientWidth;
    if (w > h) {
      this.curHeight = "30vh";
      this.isPhone = false;
    } else {
      this.curHeight = "20vw";
      this.isPhone = true;
    }
  },
  mounted() {},
  methods: {},
};
</script>




<style lang="scss" scope>
body,
html {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  font-size: 0.2rem;
}
header {
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: .5rem;
  background-color: #fff;
  .logo {
    height: 30px;
    margin-right: 5px;
  }
  span {
    color: #979898;
    font-size: 13px;
  }
  margin-bottom: 20px;
}
.swiper {
  width: 70%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.el-carousel__item h3 {
  color: #556274;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.swiperBox {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  h3 {
    position: absolute;
    z-index: 111;
    font-size: 0.3rem;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    line-height: .5rem;
    padding: 0.01rem 0;
    bottom: 0;
    a {
      color: whitesmoke;
      text-decoration: none;
    }
  }
}
main {
  padding: 0 .5rem;
  .text-gray {
    color: #555;
    font-size: 0.3rem;
    span {
      margin-left: 5px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    width: 100%;
    .rowItem {
      padding: 1%;
      width: 21%;
      margin: 0 10px;
      display: flex;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e4ecf3;
      transition: all 0.3s ease;
      margin-bottom: 20px;
      text-decoration: none;

      img {
        flex-shrink: 1;
        width: 0.6rem;
        min-width: 35px;
        height: 0.6rem;
        min-height: 35px;
      }
      .textMain {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // width: calc(100% - .6rem - 10px);
        display: table;
        table-layout: fixed;
        width: 80%;
        p {
          margin: 0;
          font-weight: bold;
        }
        .mainTitie {
          
          color: #000;
          width: 100%;
          font-size: 0.23rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis; /* for Opera */
        }
        .mainContent {
          margin-top: 3px;
          width: 100%;
          font-size: 0.16rem;
          color: #979898;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis; /* for Opera */
        }
      }
    }
  }
}
.phone {
  width: 100% !important;
}
.rowItem:hover {
  box-shadow: 0 26px 40px -24px rgb(0 36 100 / 30%);
  transform: translateY(-6px);
}
footer {
  padding: 20px .5rem;
  border-top: 1px solid #ddd;
  font-size: 12px;
  margin-top: 10px;
  background-color: #f9f9f9;
  font-size: 12px;
}
</style>
